<template>
  <a-layout class="rays-tuner-main-home">
    <a-layout-header class="header">
      <div class="top-left">
        <div class="top-title" @click="backHome">RaysTuner</div>
        <a-dropdown class="top-drop" v-if="subPageTitle == ''">
          <a class="ant-dropdown-link" @click.prevent>
            {{ toolType }}
            <svg-icon icon-class="chevron-down" class="top-drop-icon" />
          </a>
          <template #overlay>
            <a-menu @click="toolClick">
              <a-menu-item key="1">
                <a href="javascript:;">Shader优化工具</a>
              </a-menu-item>
              <!-- <a-menu-item key="2">
                            <a href="javascript:;">Mash优化工具</a>
                        </a-menu-item> -->
            </a-menu>
          </template>
        </a-dropdown>
        <div class="top_other" v-else>{{ subPageTitle }}</div>
      </div>
      <a-alert
        v-if="patListInfo.uploadTip"
        message="正在上传pat，刷新页面会丢失数据！"
        type="warning"
        show-icon
        :banner="true"
        class="pat_warning_tip"
      />
      <div class="top-tools">
        <a-tooltip>
          <template #title>帮助中心</template>
          <a class="top-icon-con" @click="helpCenter">
            <svg-icon icon-class="helpful" class="top-icon"/>
          </a>
        </a-tooltip>
        <my-cart />
        <station-message></station-message>
        <a-dropdown>
          <a class="top-icon-con" @click.prevent>
            <a-badge :count="0">
              <svg-icon icon-class="person" class="top-icon user" />
            </a-badge>
          </a>
          <template #overlay>
            <a-menu @click="accountClick">
              <a-menu-item key="1">
                <a href="javascript:;">账户设置</a>
              </a-menu-item>
              <a-menu-item key="2">
                <a href="javascript:;">退出登录</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </a-layout-header>
    <router-view></router-view>
  </a-layout>
</template>

<script>
// import { message } from 'ant-design-vue';
import { defineComponent, ref, computed } from 'vue';
// import qs from "qs";
// import axios from "axios";
import { loginOut, setMainInfo } from '@/models/tunerStorage';
import router from '@/routes';
import StationMessage from './StationMessage';
import MyCart from '@/views/shader-detect/MyCart.vue';
import store from '@/vuex/store';

export default defineComponent({
  name: 'MainHome',
  components: {
    StationMessage,
    MyCart,
  },
  
  setup() {
    // console.log('MainHome');
    const toolType = ref('Shader优化工具');

    const subPageTitle = computed(
      () => store.state.user.mainInfo.subPageTitle || ''
    );
    const patListInfo = computed(
      () => store.state.shaderTool.patListInfo || {}
    );

    const accountClick = ({ key }) => {
      // console.log('accountClick', key);
      switch (key) {
        case '1': //账户设置
          router.push({
            path: '/accounthome',
          });
          break;
        case '2': //退出登录
          loginOut();
          break;
        default:
          break;
      }
    };
    const backHome = () => {
      setMainInfo('');
      router.push({
        path: '/mainhome',
      });
    };

    const helpCenter = () => {
      setMainInfo('帮助中心');
      router.push({
        path: '/mainhome/help',
      });
    };

    const toolClick = ({ key }) => {
      // console.log('toolClick', key);
      switch (key) {
        case '1': //Shader优化工具
          break;
        case '2': //Mash优化工具
          break;
        default:
          break;
      }
    };

    return {
      toolType,
      patListInfo,
      subPageTitle,
      backHome,
      toolClick,
      accountClick,
      helpCenter,
    };
  },
});
</script>

<style scoped>
.rays-tuner-main-home {
  width: 100%;
  height: 100%;
}
.ant-layout {
  background-color:#F0F0F0;
}
.header {
  height: 48px;
  padding-top: 12px;
  padding-right: 20px;
  background-color: #fff;
  border-bottom: 1px solid #d8d8d8;
}
.top-left {
  float: left;
  height: 24px;
  width: 260px;
}
.top-title {
  float: left;
  width: 97px;
  height: 24px;
  font-size: 20px;
  font-family: Segoe UI, Segoe UI-Bold Italic;
  font-weight: 700;
  font-style: italic;
  text-align: CENTER;
  color: #222;
  line-height: 24px;
  cursor: pointer;
}
.top_other {
  float: left;
  margin-left: 32px;
  padding-left: 16px;
  border-left: 1px solid #d8d8d8;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #3c3c3c;
  line-height: 22px;
}
.pat_warning_tip {
  width: 300px;
  float: left;
  margin-top: -8px;
  margin-left: calc(50% - 476px);
}
.top-drop {
  float: right;
  height: 20px;
  line-height: 24px;
  color: #8c8c8c;
}
.top-drop-icon {
  width: 16px;
  height: 16px;
  padding-top: 6px;
}
.top-tools {
  display: inline-flex;
  float: right;
  width: 192px;
  height: 48px;
  margin-top: -12px;
  /* border: 1px solid black; */
}
.top-icon-con {
  width: 48px;
  height: 47px;
  padding-left: 12px;
}
.top-icon-con:hover {
  background-color: #f0f0f0;
}
.top-icon {
  width: 24px;
  height: 24px;
  margin-top: -2px;
  margin-right: 5px;
  color: #aaa;
  font-size: 20px;
}
.top-icon-con:hover .top-icon {
  color: #604bdc;
}
</style>
